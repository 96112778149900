import React, { useState } from "react";
import Header from '../header';
import Footer from '../footer';
import './home.css';
import Slider from '../slider';
import Carousel from '../carousel';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
function Index() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        request: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        let errorMessage = '';
        if (name === "name") {
            const nameRegex = /^[a-zA-Z\s]*$/;
            if (!nameRegex.test(value)) {
                errorMessage = 'Name can only contain letters and spaces';
            }
        }

        if (name === "email") {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.(com|in)$/;
            if (!emailRegex.test(value)) {
                errorMessage = 'Email must be in the format character@gmail.com or.in';
            }
        }
        setErrors(prevErrors => ({ ...prevErrors, [name]: errorMessage }));
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (errors.name || errors.email) {
            alert('Please fix the errors before submitting the form.');
            return;
        }
        const { name, email, request } = formData;
        const mailtoLink = `mailto:mail@moovgroup.com?subject=Request for Proposal&body=Name: ${name}%0D%0AEmail: ${email}%0D%0ASpecial Request: ${request}`;
        window.location.href = mailtoLink;

        setFormData({
            name: '',
            email: '',
            request: '',
        })
    }
    return (

        <>
            <div class="boxed_wrapper" id="home">
                <Helmet>
                    <title>Acoustic Solutions for All Spaces | Panels Direct from Manufacturer</title>
                    <meta name="description" content="Transform your interiors with stylish and effective acoustic panels. UAE’s trusted supplier for noise control and design. Call us!" />
                    <meta property="og:title" content="Acoustic and Soundproof Solutions" />
                    <meta property="og:description" content="Moov Group has been solving soundproofing, noise control, acoustical and vibration problems for over 17 years." />
                    <meta property="og:image" content="../../images/moovgroup-office.webp" />
                    <link rel="canonical" href="https://www.moovgroup.com" />
                    <link rel="alternate" href="https://www.moovgroup.com" hreflang="en-GB" />
                </Helmet>
                <Header />
                <Slider />
                <section className="about-style2-area" style={{ backgroundColor: "#5d6d7b" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5">
                                <div className="about-style2-text">
                                    <div className="sec-title">
                                        <div className="title title-pioneer">We are pioneers in <br /><span> acoustics<br /> & <br />sound proofing</span></div>
                                    </div>
                                    <div className="text1">
                                        <p style={{ color: "#000" }}>Established in the year 2007 as a Trading and Installer of Architectural Acoustic products, Moov Group has become one of the paramount multi-disciplined architectural acoustic product supply and contracting companies in the Middle East. The Moov Group is a diversified business group headquartered in Dubai, all of the business units are professionally organized and managed which have been established to focus on requirement basis along with provision of high quality products and services.<br />
                                            <br />Moov Group is a member of Acoustical Surfaces Inc, USA<br />
                                            At Moov, we design, engineer, supply and install a variety of Architectural Acoustic products and systems across a diverse range of projects creating a seamless, fast track and personal service for the client from concept design to project handover.
                                        </p>
                                    </div>
                                    <div className="button">
                                        <Link to="/about" className="btn-one">More About Us<span className="flaticon-next"></span></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7">
                                <div className="about-style2-image-box">
                                    <div className="pattern wow slideInUp" data-wow-delay="100ms" data-wow-duration="1500ms" style={{ backgroundColor: "#5d6d7b !important" }}></div>
                                    <div className="image">
                                        <div className="overlay-box">
                                            <div className="title">
                                                <div>
                                                    <h1 className="fifteen">15+</h1>
                                                    <span className="year">Years</span>
                                                </div>
                                                <div>
                                                    <h1>
                                                        <span>Experience in Acoustics and<br /> SoundProofing</span>
                                                    </h1>
                                                </div>
                                            </div>
                                            <div className="button">
                                                <Link to="/about">
                                                    <span className="icon-back"></span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="working-area working-area-bg">
                    <div className="container">
                        <div className="sec-title with-text max-width text-center wow fadeInDown" data-wow-delay="100ms" data-wow-duration="1200ms">
                            <div className="title2 clr-white">OUR SECTORS</div>
                            <p className="bottom-text">The group consists of vertically integrated strategic Business Units, coordinated to cover the following industry sectors</p>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4">
                                <div className="single-working-box wow fadeInDown" data-wow-delay="0ms">
                                    <div className="img-holder">
                                        <div className="inner architectural sprite-img">
                                            <div className="overlay-style-one"></div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="plus-icon-box"><Link to="/architectural-acoustics"><span className="icon-plus"></span></Link></div>
                                        <div className="outer-box">
                                            <div className="icon">
                                                <div className="inner">
                                                    <div className="box">
                                                        <span className="fa fa-info-circle"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>ARCHITECTURAL <br />Acoustics</h3>
                                                <p>When undertaking an architectural acoustics and soundproofing project with us at Moov Group</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="single-working-box wow fadeInDown" data-wow-delay="400ms">
                                    <div className="img-holder">
                                        <div className="inner auditorium sprite-img">
                                            <div className="overlay-style-one"></div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="plus-icon-box"><Link to="/auditorium-acoustics"><span className="icon-plus"></span></Link></div>
                                        <div className="outer-box">
                                            <div className="icon">
                                                <div className="inner">
                                                    <div className="box">
                                                        <span className="fa fa-info-circle"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>Auditorium <br />Acoustics</h3>
                                                <p>Transform your auditorium into a world of enchantment with Moov’s captivating acoustics</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="single-working-box wow fadeInDown" data-wow-delay="800ms">
                                    <div className="img-holder">
                                        <div className="inner themepark sprite-img">
                                            <div className="overlay-style-one"></div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="plus-icon-box">
                                            <Link to="/themepark-acoustics"><span className="icon-plus"></span></Link>
                                        </div>
                                        <div className="outer-box">
                                            <div className="icon">
                                                <div className="inner">
                                                    <div className="box">
                                                        <span className="fa fa-info-circle"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>Theme Park <br />Acoustics</h3>
                                                <p>Elevate Your Theme Park Experience with Moov Group’s Acoustic Solutions</p><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="single-working-box wow fadeInDown" data-wow-delay="800ms">
                                    <div className="img-holder">
                                        <div className="inner studio sprite-img">
                                            <div className="overlay-style-one"></div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="plus-icon-box">
                                            <Link to="/studio-acoustics"><span className="icon-plus"></span></Link>
                                        </div>
                                        <div className="outer-box">
                                            <div className="icon">
                                                <div className="inner">
                                                    <div className="box">
                                                        <span className="fa fa-info-circle"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>Recording Studio <br />Acoustics</h3>
                                                <p>Experience unparalleled sound quality with Moov Group’s studio acoustics solutions</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="single-working-box wow fadeInDown" data-wow-delay="800ms">
                                    <div className="img-holder">
                                        <div className="inner education sprite-img">
                                            <div className="overlay-style-one"></div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="plus-icon-box">
                                            <Link to="/education-acoustics"><span className="icon-plus"></span></Link>
                                        </div>
                                        <div className="outer-box">
                                            <div className="icon">
                                                <div className="inner">
                                                    <div className="box">
                                                        <span className="fa fa-info-circle"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>Education <br />Acoustics</h3>
                                                <p>Unlock the full potential of your students with Moov Group’s acoustic solutions</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="single-working-box wow fadeInDown" data-wow-delay="800ms">
                                    <div className="img-holder">
                                        <div className="inner hometheatre sprite-img">
                                            <div className="overlay-style-one"></div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="plus-icon-box"><Link to="/homecinema-acoustics"><span className="icon-plus"></span></Link></div>
                                        <div className="outer-box">
                                            <div className="icon">
                                                <div className="inner">
                                                    <div className="box">
                                                        <span className="fa fa-info-circle"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>Home Cinema <br />Acoustics</h3>
                                                <p>Elevate your entertainment experience with Moov’s mesmerizing acoustics</p><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="button">
                                <Link to="/sectors" className="btn-one">View All Sectors
                                    <span className="flaticon-next"></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="recently-project-style2-area">
                    <div className="container">
                        <div className="sec-title text-center">
                            <div className="pro-title">Our Projects</div>
                            <p className="bottom-text">we have successfully completed more than 900 acoustic projects<br /> over the last 17 years across the Middle East.<br /> Have a glance at our recently compeleted projects</p>
                        </div>
                        <Carousel />
                    </div>
                </section>

                <section className="services-style1-area">
                    <div className="container">
                        <div className="sec-title with-text max-width text-center wow fadeInDown" data-wow-delay="100ms" data-wow-duration="1200ms">
                            <div className="title2">Our Acoustic products</div>
                            <p className="bottom-text">With over 400 specialty soundproofing and noise control products and materials, Moov has a solution for almost any noise problem.<br /> We import all Acoustic Products from USA</p>
                        </div>
                        <div className="row">
                            <div className="col-xl-2 offset-xl-1 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1200ms">
                                    <div className="img-holder">
                                        <span className="fabrisorb sprite-img"></span>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <Link to="/fabrisorb">
                                                            <h3>Fabrisorb</h3></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-down"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>01</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                    <br /><h3>Fabrisorb</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>Fabrisorb decorative sound absorbing panels are great for offices</p>
                                            </div>
                                            <div className="read-more">
                                                <Link to="/fabrisorb"><span className="icon-next"></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div className="img-holder">
                                        <span className="fabrisorb-deco sprite-img"></span>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <Link to="/fabrisorb-deco"><h3>Fabrisorb-DECO</h3></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-down"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>02</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                    <br /><h3>Fabrisorb-DECO</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important</p>
                                            </div>
                                            <div className="read-more">
                                                <Link to="/fabrisorb-deco"><span className="icon-next"></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1200ms">
                                    <div className="img-holder">
                                        <span className="fabriwall sprite-img"></span>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <Link to="/fabriwall"><h3>Fabriwall</h3></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-down"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>03</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                    <br /><h3>Fabriwall</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>Stretch Fabric Acoustical Wall Track System</p>
                                            </div>
                                            <div className="read-more">
                                                <Link to="/fabriwall">
                                                    <span className="icon-next"></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1200ms">
                                    <div className="img-holder">
                                        <span className="decopanel sprite-img"></span>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <Link to="/decopanel"><h3>D'ecopanel</h3></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-down"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>04</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                    <br /><h3>D'ecopanel</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>D’ecopanel, the best in className decorative interior panels are tailor-made for commercial interior spaces</p>
                                            </div>
                                            <div className="read-more">
                                                <Link to="/decopanel">
                                                    <span className="icon-next"></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1200ms">
                                    <div className="img-holder">
                                        <span className="woodtec sprite-img"></span>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <Link to="/woodtec"><h3>WoodTEC</h3></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-down"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>05</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                    <br /><h3>WoodTEC</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>WoodTEC panels are high perfoming acoustic panels suitable for both walls and ceilings</p>
                                            </div>
                                            <div className="read-more">
                                                <Link to="/woodtec">
                                                    <span className="icon-next"></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-2 offset-xl-1 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1200ms">
                                    <div className="img-holder">
                                        <span className="echo-eliminator sprite-img"></span>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <Link to="/echo-eliminator"><h3>Echo Eliminator</h3></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-down"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>06</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title"><br />
                                                    <h3>Echo Eliminator</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>The Most Cost Effective Acoustical Absorbing Material On The Market</p>
                                            </div>
                                            <div className="read-more">
                                                <Link to="/echo-eliminator"><span className="icon-next"></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div className="img-holder">
                                        <span className="mlvb sprite-img"></span>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <Link to="/vinyl-barrier">
                                                            <h3>Noise S.T.O.P.™ Vinyl Barrier</h3></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-down"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>07</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title"><br />
                                                    <h3>Noise S.T.O.P.™ Vinyl Barrier</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>Mass Loaded Vinyl Noise Barrier is the best solution to build noise barriers and soundproof walls</p>
                                            </div>
                                            <div className="read-more">
                                                <Link to="/vinyl-barrier"><span className="icon-next"></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1200ms">
                                    <div className="img-holder">
                                        <span className="eco-therm sprite-img"></span>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <Link to="/eco-therm"><h3>ECO THERM</h3></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-down"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>08</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                    <br /><h3>ECO THERM</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>uniquely smooth noise dampening material coating</p>
                                            </div>
                                            <div className="read-more">
                                                <Link to="/eco-therm"><span className="icon-next"></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1200ms">
                                    <div className="img-holder">
                                        <span className="deco-woodtec sprite-img"></span>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <Link to="/deco-wood"><h3>Deco Wood</h3></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-down"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>09</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                    <br /><h3>Deco wood</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>These are acoustically absorbent panels for walls</p>
                                            </div>
                                            <div className="read-more">
                                                <Link to="/deco-wood"><span className="icon-next"></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1200ms">
                                    <div className="img-holder">
                                        <span className="acoustic-doors sprite-img"></span>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <Link to="/acoustic-doors"><h3>Acoustic Doors</h3></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-down"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>10</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title"><br />
                                                    <h3>Acoustic Doors</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>The soundproofing doors come standard in oak, ready to stain with silver hardware</p>
                                            </div>
                                            <div className="read-more">
                                                <Link to="/acoustic-doors"><span className="icon-next"></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-2 offset-xl-1 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1200ms">
                                    <div className="img-holder">
                                        <span className="pufoam sprite-img"></span>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <Link to="/pu-foam"><h3>Foam Stop POLYURETHANE</h3></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-down"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>11</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title"><br />
                                                    <h3>Foam Stop POLYURETHANE</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>Acoustic foam is an open celled foam used for acoustic treatment</p>
                                            </div>
                                            <div className="read-more">
                                                <Link to="/pu-foam"><span className="icon-next"></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div className="img-holder">
                                        <span className="diffuso sprite-img"></span>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <Link to="/diffuso"><h3>Diffuso</h3></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-down"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>12</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title"><br />
                                                    <h3>Diffuso</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>DIFFUSO is a 2-Dimensional PRD Diffuser carefully designed utilizing modulated prime root number theory.</p>
                                            </div>
                                            <div className="read-more">
                                                <Link to="/diffuso"><span className="icon-next"></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1200ms">
                                    <div className="img-holder">
                                        <span className="polymax sprite-img"></span>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <Link to="/polymax"><h3>Polymax</h3></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-down"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>13</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title"><br />
                                                    <h3>Polymax</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>POLYMAX wall panels are very decorative and easy to install on to any surface</p>
                                            </div>
                                            <div className="read-more">
                                                <Link to="/polymax"><span className="icon-next"></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1200ms">
                                    <div className="img-holder">
                                        <span className="polysorb sprite-img"></span>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <Link to="/polysorb"><h3>Polysorb</h3></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-down"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>14</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title"><br />
                                                    <h3>Polysorb</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>Our POLYSORB wall panels are very easy to install, very decorative and can be glued or mounted to any surface</p>
                                            </div>
                                            <div className="read-more">
                                                <Link to="/polysorb"><span className="icon-next"></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1200ms">
                                    <div className="img-holder">
                                        <span className="polysorb-deco sprite-img"></span>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <Link to="/polysorb-deco"><h3>Polysorb-DECO</h3></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-down"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>15</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title"><br />
                                                    <h3>Polysorb-deco</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>Our POLYSORB-DECO wall panels are very easy to install, very decorative and can be glued or mounted to any surface</p>
                                            </div>
                                            <div className="read-more">
                                                <Link to="/polysorb-deco"><span className="icon-next"></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="button">
                                <Link to="/products" className="btn-one">View All Products<span className="flaticon-next"></span></Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="slogan-style2-area slogan-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="inner-content">
                                    <div className="title">
                                        <h1>We are the Biggest <br />Acoustic Company in the Middle East<br /> Established in 2007</h1>
                                    </div>
                                    <div className="button">
                                        <Link to="tel:+97143310000" className="btn-one call-us"><i className="fa fa-phone"></i>04-3310000</Link>
                                        <Link to="/contact" className="btn-one">Discuss New Projects<span className="flaticon-next"></span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="why-choose-area why-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="why-choose-title float-left">
                                    <div className="sec-title">
                                        <div className="icon moov-logo"></div>
                                        <div className="title">
                                            <span>Why People <br />Choose </span><br /> Moov Group
                                        </div>
                                    </div>
                                    <ul>
                                        <li>World className Standard Products</li>
                                        <li>Budget based Solutions & Customisations</li>
                                        <li>Proper Execution & Timely Completion</li>
                                    </ul>
                                    <div className="button">
                                        <Link to="/contact" className="btn-one">Make an Appointment<span className="flaticon-next"></span></Link>
                                    </div>
                                </div>
                                <div className="why-choose-content float-right">
                                    <div className="single-box redbg">
                                        <div className="icon-holder">
                                            <span className="fa fa-users"></span>
                                        </div>
                                        <div className="text-holder">
                                            <h3>Experienced Team</h3>
                                            <p>Qualified and Skilled Technical Team deployed for Projects</p>
                                        </div>
                                    </div>
                                    <div className="single-box whitebg">
                                        <div className="icon-holder">
                                            <span className="fa fa-television"></span>
                                        </div>
                                        <div className="text-holder">
                                            <h3>Technology Partnership</h3>
                                            <p>We are proud member of Acoustical Surfaces Inc, USA<br />
                                                We represent Acoustical Surfaces USA in Middle East region and supplying products made in USA</p>
                                        </div>
                                    </div>

                                    <div className="single-box whitebg">
                                        <div className="icon-holder">
                                            <span className="fa fa-headphones"></span>
                                        </div>
                                        <div className="text-holder">
                                            <h3>Acoustics and Soundproofing</h3>
                                            <p>We are specialized in acoustics and we do turnkey acoustic projects</p>
                                        </div>
                                    </div>

                                    <div className="single-box blackbg">
                                        <div className="icon-holder">
                                            <span className="fa fa-thumbs-up"></span>
                                        </div>
                                        <div className="text-holder">
                                            <h3>Customer Satisfaction</h3>
                                            <p>We value our customers and provide the best solutions<br />
                                                Quality is Measurable, We realize it & We deliver it</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="company-overview-area">
                    <div className="sec-title text-center slogan-style2-area slogan-bg">
                        <div className="title2">Our Milestone</div>
                        <div className="title clr-white">Our journey started from 2007, at present this is where we stand now</div>
                        <div className="container">
                            <div className="row fact-counter">
                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-fact-counter wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="1500ms">
                                        <div className="count-box">
                                            <h1>
                                                <span className="timer" data-from="1" data-to="891" data-speed="5000" data-refresh-interval="50">891</span><br />
                                            </h1>
                                        </div>
                                        <div className="title">
                                            <h3>Projects </h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-fact-counter wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                                        <div className="count-box">
                                            <h1>
                                                <span className="timer" data-from="1" data-to="1026" data-speed="5000" data-refresh-interval="50">1026</span>
                                            </h1>
                                        </div>
                                        <div className="title">
                                            <h3>Clients</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-fact-counter wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                        <div className="count-box">
                                            <h1>
                                                <span className="timer" data-from="1" data-to="300" data-speed="5000" data-refresh-interval="50">300</span>
                                            </h1>
                                        </div>
                                        <div className="title">
                                            <h3>Products</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-fact-counter wow fadeInLeft" data-wow-delay="400ms" data-wow-duration="1500ms">
                                        <div className="count-box">
                                            <h1>
                                                <span className="timer" data-from="1" data-to="100" data-speed="5000" data-refresh-interval="50">100</span>
                                            </h1>
                                        </div>
                                        <div className="title">
                                            <h3>Our Team</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-details-area">
                    <div className="sec-title text-center">
                        <div className="title2">Our Contact Details</div>
                        <p className="bottom-text">Discuss on your Project Requirement with us!!</p>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="contact-details-content">
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12">
                                            <div className="content-info-box content-box-bg">
                                                <div className="con-inner">
                                                    <div className="con-inner-top">
                                                        <div className="con-inner-box wow fadeInDown animated">
                                                            <div className="con-inner-box-top">
                                                                <span className="icon-global"></span>
                                                                <h3>Visit Our<br /> <span className="addcol">Dubai Head Office</span></h3>
                                                            </div>
                                                            <div className="con-inner-box-bot">
                                                                <p>MOOV GROUP<br />
                                                                    Office No# B2002, Latifa Tower,<br />
                                                                    Sheikh Zayed Road,<br />
                                                                    P.O. Box 113239 | Dubai - U.A.E.<br /></p>
                                                            </div>
                                                        </div>
                                                        <div className="con-inner-box wow fadeInUp animated">
                                                            <div className="con-inner-box-top">
                                                                <span className="icon-global"></span>
                                                                <h3>Visit Our<br />
                                                                    <span className="addcol"> Abu Dhabi Office</span>
                                                                </h3>
                                                            </div>
                                                            <div className="con-inner-box-bot">
                                                                <p>MOOV CONSTRUCTION SOLUTIONS LLC<br />
                                                                    Office # 5 | ICAD - Building 5<br />
                                                                    P.O. Box 132852 | Abu Dhabi - U.A.E.<br /><br />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="con-inner-box wow fadeInDown animated">
                                                            <div className="con-inner-box-top">
                                                                <span className="icon-global"></span>
                                                                <h3>Visit Our<br /><span className="addcol"> Deco Design Lab</span></h3>
                                                            </div>
                                                            <div className="con-inner-box-bot">
                                                                <p>MOOV GENERAL TRADING LLC<br />
                                                                    W/H No. 7 | Dubai Industrial Park-1<br />
                                                                    P.O. Box 113239 | Dubai - U.A.E.<br /><br /></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="con-inner-bot">
                                                        <div className="con-inner-bot-in">
                                                            <div className="contact-details-form-box">
                                                                <div className="title-box">
                                                                    <h2>Request for Proposal</h2>
                                                                </div>
                                                                <div className="contact-details-box">
                                                                    <form className="contact-details-form" onSubmit={handleSubmit}>
                                                                        <div className="row">
                                                                            <div className="col-xl-6">
                                                                                <div className="single-box">
                                                                                    <input type="text" name="name" value={formData.name} placeholder="Name" required onChange={handleChange} style={{ margin: "0px" }} />
                                                                                    {errors.name && <p className="error" style={{ color: "red", fontSize: "12px", padding: "0px" }}>{errors.name}</p>}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6">
                                                                                <div className="single-box">
                                                                                    <input type="email" name="email" value={formData.email} placeholder="Email" required onChange={handleChange} style={{ margin: "0px" }} />
                                                                                    {errors.email && <p className="error" style={{ color: "red", fontSize: "12px", padding: "0px" }}>{errors.email}</p>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-xl-12">
                                                                                <div className="single-box">
                                                                                    <textarea name="request" value={formData.request} placeholder="Special Request" required onChange={handleChange}></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-xl-12">
                                                                                <div className="single-box">
                                                                                    <button className="btn-one" type="submit">Send Request<span className="flaticon-next"></span></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}

export default Index;
