import React from 'react';
import Home from './pages/home/home';
import About from './pages/about/about';
import Sectors from './pages/sectors/sectors';
import Projects from './pages/projects/projects';
import Products from './pages/products/products';
import Event from './pages/events/event';
import Career from './pages/career/career';
import Contact from './pages/contact/contact';
import Projectsv1 from './pages/projects/projects-v1';
import Projectsv2 from './pages/projects/projects-v2';
import Projectsv3 from './pages/projects/projects-v3';
import Projectsv4 from './pages/projects/projects-v4';
import Projectsv5 from './pages/projects/projects-v5';
import Projectsv6 from './pages/projects/projects-v6';
import Fabrisorb from './pages/fabrisorb/fabrisorb';
import FabrisorbDeco from './pages/fabrisorb-deco/fabrisorb-deco';
import Fabriwall from './pages/fabriwall/fabriwall';
import Decopanel from './pages/decopanel/decopanel';
import Petpanel from './pages/pet-panel/pet-panel';
import Woodtec from './pages/woodtec/woodtec';
import Woodteclinear from './pages/woodtec/woodtec-linear-acoustic-panel';
import Woodtecperforated from './pages/woodtec/woodtec-perforated-acoustic-panel';
import Woodtecslotted from './pages/woodtec/woodtec-slotted-acoustic-panel';
import Woodtecdesigner from './pages/woodtec/woodtec-designer-acoustic-panel';
import Echoeliminator from './pages/echo-eliminator/echo-eliminator';
import Vinylbarrier from './pages/vinyl-barrier/vinyl-barrier';
import Ecotherm from './pages/eco-therm/eco-therm';
import Decowood from './pages/deco-wood/deco-wood';
import Acousticdoors from './pages/acoustic-doors/acoustic-doors';
import Pufoam from './pages/pu-foam/pu-foam';
import Diffuso from './pages/diffuso/diffuso';
import Polymax from './pages/polymax/polymax';
import Polysorb from './pages/polysorb/polysorb';
import Polysorbdeco from './pages/polysorb-deco/polysorb-deco';
import Decobaffles from './pages/decopanel/decopanel-baffles-clouds';
import Decogrooved from './pages/decopanel/decopanel-grooved-design';
import Decoplain from './pages/decopanel/decopanel-plain-colors';
import Decorolls from './pages/decopanel/decopanel-plain-print-rolls';
import Decodesign from './pages/decopanel/decopanel-print-design';
import Decotiles from './pages/decopanel/decopanel-tiles';
import Fabribaffles from './pages/fabrisorb/fabrisorb-acoustic-baffles';
import Fabriclouds from './pages/fabrisorb/fabrisorb-acoustic-clouds';
import Fabridesign from './pages/fabrisorb/fabrisorb-acoustic-designer-panels';
import Fabrisorbwall from './pages/fabrisorb/fabrisorb-acoustic-wall-panels';
import Fabrifabric from './pages/fabriwall/fabriwall-fabric';
import Fabripanel from './pages/fabriwall/fabriwall-panel';
import Fabritrack from './pages/fabriwall/fabriwall-track';
import Polyceiling from './pages/polysorb/polysorb-ceiling-panels';
import Polydesign from './pages/polysorb/polysorb-designer-panels';
import Polywall from './pages/polysorb/polysorb-acoustic-wall-panels';
import Polybaffles from './pages/polysorb/polysorb-ceiling-baffles';
import Putrap from './pages/pu-foam/pu-foam-bass-trap-design';
import Pucylind from './pages/pu-foam/pu-foam-cylindrical-panels';
import Puegg from './pages/pu-foam/pu-foam-eggcrate-design';
import Puflat from './pages/pu-foam/pu-foam-flat-design';
import Pupyramid from './pages/pu-foam/pu-foam-pyramid-design';
import Acousticwall from './pages/sectors/acoustic-wall';
import Architectural from './pages/sectors/architectural-acoustics';
import Auditorium from './pages/sectors/auditorium-acoustics';
import Commercial from './pages/sectors/commercial-acoustics.js';
import Education from './pages/sectors/education-acoustics';
import Entertainment from './pages/sectors/entertainment-acoustics';
import Homecinema from './pages/sectors/homecinema-acoustics';
import Hospitality from './pages/sectors/hospitality-acoustics';
import Industrial from './pages/sectors/industrial-acoustics';
import Seating from './pages/sectors/seating-solutions';
import Studio from './pages/sectors/studio-acoustics';
import Themepark from './pages/sectors/themepark-acoustics';
import Africahall from './pages/projects/africa-hall-acoustics';
import Americanschool from './pages/projects/american-school-acoustics';
import Portauthority from './pages/projects/auditorium-port-authority-acoustics';
import Azamtv from './pages/projects/azam-tv-acoustics';
import Beeah from './pages/projects/beeah-acoustics';
import Dancefm from './pages/projects/dance-fm-acoustics';
import Dubaiairport from './pages/projects/dubai-airport-acoustics';
import Dubaipark from './pages/projects/dubai-park-acoustics';
import Eibfs from './pages/projects/eibfs-acoustics';
import Emal from './pages/projects/emal-acoustics';
import Emarati from './pages/projects/emarati-school-acoustics';
import Etisalat from './pages/projects/etisalat-auditorium-acoustics';
import Ferrari from './pages/projects/ferrari-world-acoustics';
import Fujairah from './pages/projects/fujairah-auditorium-acoustics';
import Hilton from './pages/projects/hilton-hotel-acoustics';
import Kalbatv from './pages/projects/kalba-tv-acoustics';
import Kapsarc from './pages/projects/kapsarc-acoustics';
import Khalifa from './pages/projects/khalifa-university-acoustics';
import Louvre from './pages/projects/louvre-acoustics';
import Museum from './pages/projects/museum-of-the-future-acoustics';
import Nationalbank from './pages/projects/national-bank-acoustics';
import Novocinemas from './pages/projects/novo-cinemas-acoustics';
import Novopark from './pages/projects/novo-park-acoustics';
import Orbi from './pages/projects/orbi-theatre-acoustics';
import Presidential from './pages/projects/presidential-palace-acoustics';
import Primaryschool from './pages/projects/primary-schools-acoustics';
import Privatehome from './pages/projects/private-home-cinema-acoustics';
import Rashid from './pages/projects/rashid-library-acoustics';
import Skynews from './pages/projects/skynews-acoustics';
import Sportscomplex from './pages/projects/sports-complex-acoustics';
import Thumbay from './pages/projects/thumbay-group-acoustics';
import Utc from './pages/projects/utc-aerospace-systems-acoustics';
import Voxcinema from './pages/projects/vox-cinema-acoustics';
import Whotel from './pages/projects/w-hotel-acoustics';
import Search from './pages/search/search';
import Abudhabi from './pages/abu-dhabi/abu-dhabi';
import Bahrain from './pages/bahrain/bahrain';
import Dubai from './pages/dubai/dubai';
import Kuwait from './pages/kuwait/kuwait';
import Oman from './pages/oman/oman';
import Qatar from './pages/qatar/qatar';
import Saudiarabia from './pages/saudi-arabia/saudi-arabia';
import Sharjah from './pages/sharjah/sharjah';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './components/scroll-to-top/scroll-to-top';
import GemsFounderScl from './pages/projects/gems-founders-scl.js';
import Mandir from './pages/projects/mandir.js';
function App(){
  return(
  <div className='App'>
      <Router>
      <ScrollToTop>
        <Routes>
          <Route exact path='/' element={<Home />} /> 
          <Route exact path='/about' element={<About />} />
          <Route exact path='/sectors' element={<Sectors />} />
          <Route exact path='/projects' element={<Projects />} />
          <Route exact path='/products' element={<Products />} />
          <Route exact path='/event' element={<Event />} />
          <Route exact path='/career' element={<Career />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/projects-v1' element={<Projectsv1 />} />
          <Route exact path='/projects-v2' element={<Projectsv2 />} />
          <Route exact path='/projects-v3' element={<Projectsv3 />} />
          <Route exact path='/projects-v4' element={<Projectsv4 />} />
          <Route exact path='/projects-v5' element={<Projectsv5 />} />
          <Route exact path='/projects-v6' element={<Projectsv6 />} />
          <Route exact path='/fabrisorb' element={<Fabrisorb />} />
          <Route exact path='/fabrisorb-deco' element={<FabrisorbDeco />} />
          <Route exact path='/fabriwall' element={<Fabriwall />} />
          <Route exact path='/decopanel' element={<Decopanel />} />
          <Route exact path='/pet-panel' element={<Petpanel />} />
          <Route exact path='/woodtec' element={<Woodtec />} />
          <Route exact path='/woodtec-linear-acoustic-panel' element={<Woodteclinear />} />
          <Route exact path='/woodtec-perforated-acoustic-panel' element={<Woodtecperforated />} />
          <Route exact path='/woodtec-slotted-acoustic-panel' element={<Woodtecslotted />} />
          <Route exact path='/woodtec-designer-acoustic-panel' element={<Woodtecdesigner />} />
          <Route exact path='/echo-eliminator' element={<Echoeliminator />} />
          <Route exact path='/vinyl-barrier' element={<Vinylbarrier />} />
          <Route exact path='/eco-therm' element={<Ecotherm />} />
          <Route exact path='/deco-wood' element={<Decowood />} />
          <Route exact path='/acoustic-doors' element={<Acousticdoors />} />
          <Route exact path='/pu-foam' element={<Pufoam />} />
          <Route exact path='/diffuso' element={<Diffuso />} />
          <Route exact path='/polymax' element={<Polymax />} />
          <Route exact path='/polysorb' element={<Polysorb />} />
          <Route exact path='/polysorb-deco' element={<Polysorbdeco />} />
          <Route exact path='/decopanel-baffles-clouds' element={<Decobaffles />} />
          <Route exact path='/decopanel-grooved-design' element={<Decogrooved />} />
          <Route exact path='/decopanel-plain-colors' element={<Decoplain />} />
          <Route exact path='/decopanel-plain-print-rolls' element={<Decorolls />} />
          <Route exact path='/decopanel-print-design' element={<Decodesign />} />
          <Route exact path='/decopanel-tiles' element={<Decotiles />} />
          <Route exact path='/fabrisorb-acoustic-baffles' element={<Fabribaffles />} />
          <Route exact path='/fabrisorb-acoustic-clouds' element={<Fabriclouds />} />
          <Route exact path='/fabrisorb-acoustic-designer-panels' element={<Fabridesign />} />
          <Route exact path='/fabrisorb-acoustic-wall-panels' element={<Fabrisorbwall />} />
          <Route exact path='/fabriwall-fabric' element={<Fabrifabric />} />
          <Route exact path='/fabriwall-panel' element={<Fabripanel />} />
          <Route exact path='/fabriwall-track' element={<Fabritrack />} />
          <Route exact path='/polysorb-ceiling-panels' element={<Polyceiling />} />
          <Route exact path='/polysorb-designer-panels' element={<Polydesign />} />
          <Route exact path='/polysorb-acoustic-wall-panels' element={<Polywall />} />
          <Route exact path='/polysorb-ceiling-baffles' element={<Polybaffles />} />
          <Route exact path='/pu-foam-bass-trap-design' element={<Putrap />} />
          <Route exact path='/pu-foam-cylindrical-panels' element={<Pucylind />} />
          <Route exact path='/pu-foam-eggcrate-design' element={<Puegg />} />
          <Route exact path='/pu-foam-flat-design' element={<Puflat />} />
          <Route exact path='/pu-foam-pyramid-design' element={<Pupyramid />} />
          <Route exact path='/acoustic-wall' element={<Acousticwall />} />
          <Route exact path='/architectural-acoustics' element={<Architectural />} />
          <Route exact path='/auditorium-acoustics' element={<Auditorium />} />
          <Route exact path='/commercial-acoustics' element={<Commercial />} />
          <Route exact path='/education-acoustics' element={<Education />} />
          <Route exact path='/entertainment-acoustics' element={<Entertainment />} />
          <Route exact path='/homecinema-acoustics' element={<Homecinema />} />
          <Route exact path='/hospitality-acoustics' element={<Hospitality />} />
          <Route exact path='/industrial-acoustics' element={<Industrial />} />
          <Route exact path='/seating-solutions' element={<Seating />} />
          <Route exact path='/studio-acoustics' element={<Studio />} />
          <Route exact path='/themepark-acoustics' element={<Themepark />} />
          <Route exact path='/africa-hall-acoustics' element={<Africahall />} />
          <Route exact path='/american-school-acoustics' element={<Americanschool />} />
          <Route exact path='/auditorium-port-authority-acoustics' element={<Portauthority />} />
          <Route exact path='/azam-tv-acoustics' element={<Azamtv />} />
          <Route exact path='/beeah-acoustics' element={<Beeah />} />
          <Route exact path='/dance-fm-acoustics' element={<Dancefm />} />
          <Route exact path='/dubai-airport-acoustics' element={<Dubaiairport />} />
          <Route exact path='/dubai-park-acoustics' element={<Dubaipark />} />
          <Route exact path='/eibfs-acoustics' element={<Eibfs />} />
          <Route exact path='/emal-acoustics' element={<Emal />} />
          <Route exact path='/emarati-school-acoustics' element={<Emarati />} />
          <Route exact path='/gems-founders-scl' element={<GemsFounderScl />} />
          <Route exact path='/mandir' element={<Mandir />} />
          <Route exact path='/etisalat-auditorium-acoustics' element={<Etisalat />} />
          <Route exact path='/ferrari-world-acoustics' element={<Ferrari />} />
          <Route exact path='/fujairah-auditorium-acoustics' element={<Fujairah />} />
          <Route exact path='/hilton-hotel-acoustics' element={<Hilton />} />  
          <Route exact path='/kalba-tv-acoustics' element={<Kalbatv />} />
          <Route exact path='/kapsarc-acoustics' element={<Kapsarc />} />
          <Route exact path='/khalifa-university-acoustics' element={<Khalifa />} />
          <Route exact path='/louvre-acoustics' element={<Louvre />} />
          <Route exact path='/museum-of-the-future-acoustics' element={<Museum />} />
          <Route exact path='/national-bank-acoustics' element={<Nationalbank />} />
          <Route exact path='/novo-cinemas-acoustics' element={<Novocinemas />} />
          <Route exact path='/novo-park-acoustics' element={<Novopark />} />
          <Route exact path='/orbi-theatre-acoustics' element={<Orbi />} />
          <Route exact path='/presidential-palace-acoustics' element={<Presidential />} />
          <Route exact path='/primary-schools-acoustics' element={<Primaryschool />} />
          <Route exact path='/private-home-cinema-acoustics' element={<Privatehome />} />
          <Route exact path='/rashid-library-acoustics' element={<Rashid />} />
          <Route exact path='/skynews-acoustics' element={<Skynews />} />
          <Route exact path='/sports-complex-acoustics' element={<Sportscomplex />} />
          <Route exact path='/thumbay-group-acoustics' element={<Thumbay />} />
          <Route exact path='/utc-aerospace-systems-acoustics' element={<Utc />} />
          <Route exact path='/vox-cinema-acoustics' element={<Voxcinema />} />
          <Route exact path='/w-hotel-acoustics' element={<Whotel />} />
          <Route exact path='/search' element={<Search />} />
          <Route exact path='/abu-dhabi' element={<Abudhabi />} />
          <Route exact path='/bahrain' element={<Bahrain />} />
          <Route exact path='/dubai' element={<Dubai />} />
          <Route exact path='/kuwait' element={<Kuwait />} />
          <Route exact path='/oman' element={<Oman />} />
          <Route exact path='/qatar' element={<Qatar />} />
          <Route exact path='/saudi-arabia' element={<Saudiarabia />} />
          <Route exact path='/sharjah' element={<Sharjah />} />
        </Routes>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
