import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './projects.css';
import Carousel from '../carousel';
import Mandir1 from '../../images/moov-pro1.jpeg';
import Mandir2 from '../../images/moov-pro1.jpeg';
import Mandir3 from '../../images/moov-proj1.jpeg';
import Mandir4 from '../../images/moov-proje1.jpeg';
import Mandir5 from '../../images/moov-pro1.jpeg';
function Mandir() {
    return (

        <>
            <div className="boxed_wrapper" id="project">
                <Helmet>
                    <title>MANDIR Acoustics Works - Moov Group</title>
                    <meta name="description" content="" />
                    <meta property="og:title" content="MANDIR" />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content="../../images/moovgroup-office.webp" />
                    <link rel="canonical" href="https://www.moovgroup.com/mandir" />
                    <link rel="alternate" href="https://www.moovgroup.com/mandir" hreflang="en-GB" />
                </Helmet>
                <Header />
                <div id="moov">
                    <section className="breadcrumb-section projects-bg">
                        <div className="container">
                            <h1 className="page-title">mandir</h1>
                            <span className="page-tag">FABRIWALL system and ACOUSTIC BAFFLES Installation</span>
                        </div>
                    </section>

                    <section className="portfolio-section section-gap-top" id="portfolio">
                        <div className="container">

                            <div className="portfolio-items">
                                <div className="portfolio-item portfolio-style-four">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-container1">
                                                <h1 className="portfolio-previous">MANDIR</h1>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-container2">
                                                <Link to="/gems-founders-scl" className="portfolio-next">Next &raquo;</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="line-footer"></span>
                                </div>
                            </div>

                            <div className="row portfolio-items portfolio-filter" id="portsub" style={{ position: "relative", height: "580px" }}>
                                <div className="col-lg-3 col-md-6 col-sm-6 filter-item design web" style={{ position: "absolute", right: "0px", bottom: "0px" }}>
                                    <div className="portfolio-item">
                                        <div className="portfolio-thumb">
                                            <div className="thumb">
                                                <img src={Mandir1} alt="Mandir Acoustics - 1" />
                                            </div>
                                        </div>
                                        <div className="portfolio-desc">
                                            <span className="cat">Abu Dhabi</span>
                                            <h5 className="title"><Link to="/gems-founders-scl">Mandir</Link></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 filter-item game" style={{ position: "absolute", right: "295px", bottom: "0px" }}>
                                    <div className="portfolio-item big-item">
                                        <div className="portfolio-thumb">
                                            <div className="thumb">
                                                <img src={Mandir2} alt="Mandir Acoustics - 2" />
                                            </div>
                                        </div>
                                        <div className="portfolio-desc">
                                            <span className="cat">Abu Dhabi</span>
                                            <h5 className="title"><Link to="/gems-founders-scl">Mandir</Link></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 filter-item web" style={{ position: "absolute", right: "885px", bottom: "0px" }}>
                                    <div className="portfolio-item">
                                        <div className="portfolio-thumb">
                                            <div className="thumb">
                                                <img src={Mandir3} alt="Mandir Acoustics - 3" />
                                            </div>
                                        </div>
                                        <div className="portfolio-desc">
                                            <span className="cat">Abu Dhabi</span>
                                            <h5 className="title"><Link to="/gems-founders-scl">Mandir</Link></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 filter-item design" style={{ position: "absolute", right: "0px", bottom: "290px" }}>
                                    <div className="portfolio-item">
                                        <div className="portfolio-thumb">
                                            <div className="thumb">
                                                <img src={Mandir4} alt="Mandir Acoustics - 4" />
                                            </div>
                                        </div>
                                        <div className="portfolio-desc">
                                            <span className="cat">Abu Dhabi</span>
                                            <h5 className="title"><Link to="/gems-founders-scl">Mandir</Link></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 filter-item game web" style={{ position: "absolute", right: "885px", bottom: "290px" }}>
                                    <div className="portfolio-item">
                                        <div className="portfolio-thumb">
                                            <div className="thumb">
                                                <img src={Mandir5} alt="Mandir Acoustics - 5" />
                                            </div>
                                        </div>
                                        <div className="portfolio-desc">
                                            <span className="cat">Abu Dhabi</span>
                                            <h5 className="title"><Link to="/gems-founders-scl">Mandir</Link></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="recently-project-style2-area">
                        <div className="container">
                            <div className="sec-title text-center">
                                <div className="pro-title">Our Projects</div>
                                <p className="bottom-text">we have successfully completed more than 900 acoustic projects<br /> over the last 17 years across the Middle East.<br /> Have a glance at our recently compeleted projects</p>
                            </div>
                            <Carousel />
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Mandir;